.footer {
  background-color: #0C1621;
  /* Dark Blue Background Color */
  color: #fff;
  /* White Text Color on Dark Background */
  padding: 20px;
  font-size: 14px;
  /* Blue Accent Line at the Top */

// .container {
//   padding-left: 15px;
//   padding-right: 15px;
// }


/* Style links with a lighter blue color */
a {
  color: #90c0e0;
  /* Lighter Blue Link Color */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #6a96b9;
  /* Slightly Darker Blue on Hover */
}


.foot-head {
  font-family: Century Gothic;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.04em;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;

}


.foot-head:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  // left: 25%;
  border-bottom: 1px solid white;

}
.foot-desc {
  font-family: Century Gothic;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0em;
  color: #E3E8ED;

}

}