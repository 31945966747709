.main-container {
  background: #273441;
  padding: 50px;
}

.main-light-container {
  background: #F8FBFF;
  padding: 50px;
}


.title {
  font-family: "Century Gothic";
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 2rem;
  color: #E3E8ED;
}

.blue-title {
  color: #00529C;

}

.description {
  font-family: "Century Gothic";
  font-size: 0.9rem;
  font-weight: 400;
  /* line-height: 40px; */
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 1.5rem;
  color: #E3E8ED;
}

.desc-1 {
  font-weight: 700;
}

.dark-font {
  color: #273441;
}

.about-imgs {
  height: 350px;
  width: auto;
}