.roofing-calculator {
  padding: 5em;

  // height: 100vh;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      font-weight: bold;
    }

    select,
    input[type="radio"] {
      width: 100%;
      padding: 10px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      background-color: #fff;
      //   &:focus {
      //     outline: none;
      //     box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
      //   }
    }
  }

  .radio-inline {
    margin-right: 20px;

    input[type="radio"] {
      margin-right: 5px;
    }
  }

  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }


}

.nos-w {
  width: 118.57px;
}

.calc-p {
  padding: 10.6px 18px !important;
}

.p-line {
  line-height: normal;
  font-family: Century Gothic;
}

.fontf {
  font-family: Century Gothic;
}

.results {
  margin-top: 3.5rem
}

.bg-End {
  background-color: #273441;
  color: #E3E8ED !important;
  padding: 4rem;
  .heading {
    color: #E3E8ED;

  }

}

.calc-img {
  background-image: url("../assets/images/calc.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 10px;
}


.roof-button {

  button {
    font-family: Century Gothic;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    padding: 10px 36px;
    border-radius: 0;
    background-color: #E3E8ED;
  }
}