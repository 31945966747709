.bg-image {
  background-image: url('../assets/images/contact.jpg');
  background-position: center;
  background-size: cover;
}

.form-container {
  padding: 20px;
  font-family: "Century Gothic";
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: left;
}

.form-wrap {
  // margin-left: auto;
  width: 350px;
}

.column-heading {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.column-subheading {
  font-family: "Century Gothic";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #E3E8ED;
}

.bg-row {
  background-color: #273441;
  // padding: 3rem;

}

.call-info {
  font-family: "Century Gothic";
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 29.43px;
  color: #E3E8ED;

  .phone-icon {
    color: #E3E8ED;
    width: 40px;
    height: 40px;
  }

  .phone-number {
    color: #E3E8ED;
    margin-left: 10px;
  }
}

.bg-form {
  background-color: #EAF0F4;
  padding: 2.5rem;
  font-family: "Century Gothic";
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  .form-group {
    // margin-bottom: 20px;

    label {
      font-weight: 700;
    }
  }

  .alerts {
    height: 50px;
  }

  .contact-button {

    button {
      border-width: medium;
      padding: 10px 32px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 0;
      border-color: #273441;
    }
  }

  .under-submit-text {
    line-height: normal;
    margin-top: 10px;
  }


}


