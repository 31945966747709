.place-autocomplete-map {
  max-width: 600px;
  margin: 0 auto;

  .input-group {
    margin-bottom: 20px;
  }
}




.list-group {
  z-index: 1;
  position: absolute;
  margin-left: 0;
}

.mapwrap {
  padding: 5rem 0;
}


.icon {
  background-image: url("../assets/images/Search.svg");
  background-position: 16px 16px;
  background-repeat: no-repeat;
  background-size: 15px;
}


.form .fa-search {

  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;

}

.form span {

  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;

}

.left-pan {
  padding-left: 7px;
}

.left-pan i {

  padding-left: 10px;
}

.form-input {
  border: 0.8px solid;
  height: 47px;
  text-indent: 40px;
  border-radius: 0;
  margin-top: 18px;
}

.form-input:focus {

  box-shadow: none;
  border: none;
}

.heading {
  font-family: Century Gothic;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #273441;
}

.subheading {
  font-family: Century Gothic;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0em;
  margin-top: 3.5rem;
  text-align: left;
}


.calculate-button {
  margin-top: 2rem;

  button {
    border-width: revert;
    padding: 10px 18px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0;
    border-color: #273441;
  }
}


@media (min-width:320px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  .mapwrap {
    padding: 5rem 0;
  }
}