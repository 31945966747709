.header {
  a {
    color: #273441;
    text-decoration: none;
  }
  .active {
    text-decoration: underline;
    text-underline-offset: 0.3em;
    text-decoration-thickness: 2px;
  }

  .nav-item {
    font-family: Century Gothic;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    // text-align: left;

  }

  // .nav-link {
  //     font-size: larger;
  //     font-weight: 600;
  // }

  .head-button {

    button {
      // border-width: medium;
      font-family: Century Gothic;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      padding: 12px 24px;
      border-radius: 0;
      border-color: #273441;
      border-width: revert-layer;
    }
  }
}


.navbar-nav {
  --bs-nav-link-padding-y:1.5rem;
}